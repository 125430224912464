import React from "react";

import { Link } from "gatsby";
import { CONTACT, PATHS, SERVICES } from "../../config/urls";

import emtLogo from "../../images/emtColorLogo.svg";
import ArrowDown from "../../images/arrow-down.svg";
import hamburguerIcon from "./assets/hamburguer-menu-icon.svg";

import {
  Container,
  MenuContainer,
  MenuDesktop,
  ContactUs,
  MenuDesktopBrand,
  MenuDesktopList,
  MenuDesktopItem,
  MenuDesktopServicesItem,
  Arrow,
  DropdownContent,
  MenuMobile,
  MenuMobileButton,
  HamburguerIcon,
  MobileServices,
  WhiteLine,
  PhoneLink,
} from "./styles";

const Topbar = () => {
  const [open, setOpen] = React.useState(false);
  const [openMobileServices, setOpenMobileServices] = React.useState(false);

  return (
    <>
      <MenuContainer>
        <MenuDesktop>
          <Container>
            <MenuDesktopBrand>
              <Link to={PATHS.INDEX}>
                <img src={emtLogo} alt="Life EMT logo" />
              </Link>
            </MenuDesktopBrand>
            <MenuDesktopList>
              <MenuDesktopItem>
                <ContactUs href='#callBack'>
                APPLY NOW
                </ContactUs>
              </MenuDesktopItem>
            </MenuDesktopList>
          </Container>
        </MenuDesktop>

        {/* MOBILE */}

        <MenuMobile>
          <Container>
            <MenuDesktopBrand>
              <Link to={PATHS.INDEX}>
                <img src={emtLogo} alt="Life EMT logo" />
              </Link>
            </MenuDesktopBrand>
          </Container>
        </MenuMobile>
      </MenuContainer>
    </>
  );
};

export default Topbar;
