import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";
import { DESKTOP_1200, DESKTOP_1024, TABLET_992, MOBILE_460 } from "../../styles/globals/sizes";

export const MenuContainer = styled.nav`
  width: 100%;
  height: 90px;
  background-color: ${COLORS.white};
  z-index: 10;
  position: fixed;
  top: 0px;
  left: 0;
  transition: 0.5s;
`;

export const MenuDesktop = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const ContactUs = styled.a`
  padding: 14px 60px;
  border-radius: 25px;
  font: bold 16px/21px Roboto;
  color: ${COLORS.white};
  background-color: ${COLORS.vermilion};
  border: 0;
  word-wrap: break-word;
  &:hover {
    filter: grayscale(50%);
  }
`;

export const Container = styled.div`
  width: 90%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1500px;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: center;
  }
`;

export const MenuDesktopBrand = styled.a`
  transition: all 0.2s ease;
  &:hover {
    opacity: 0.7;
  }
  img {
    width: 140px;
    height: auto;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    margin-left: 20px;
  }
  @media (max-width: ${TABLET_992}px) {
    margin: 0;
    img {
      width: 77px;
      height: 36px;
    }
    &:hover {
      opacity: 1;
    }
  }
`;

export const MenuDesktopList = styled.ul`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const MenuDesktopItem = styled.li`
  margin: 0 20px;
  padding: 10px 0;
  font: 400 15px/20px Roboto;
  position: relative;
  transition: all 0.2s linear;
  cursor: pointer;
  color: ${(props) => (props.isOpen ? COLORS.darkGray : COLORS.grayTopbarItem)};
  &:hover,
  &:active {
    color: ${COLORS.darkGray};
  }

  @media (max-width: ${DESKTOP_1200}px) {
    margin: 0 15px;
    font: 400 14px/20px Roboto;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    margin: 0 12px;
    font: 400 14px/20px Roboto;
  }
`;

export const MenuDesktopServicesItem = styled.li`
  margin: 0 20px;
  padding: 10px 0;
  font: 400 15px/20px Roboto;
  position: relative;
  transition: all 0.2s linear;
  cursor: pointer;
  color: ${COLORS.grayTopbarItem};
  img {
    width: 10px;
    transition: 0.5s linear;
    margin-left: 10px;
    transform: rotate(180deg);
  }
  div {
    width: 160px;
    height: auto;
    border-radius: 11px;
    background-color: ${COLORS.vermilion};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 40px;
    right: -10px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease;
    cursor: pointer;
    display: none;
    a,
    li {
      padding: 12px 16px;
      text-align: right;
      color: ${COLORS.white};
      display: block;
      text-transform: uppercase;
      font: 600 13px/18px Roboto;
      &:first-child {
        padding-top: 18px;
      }
      &:last-child {
        padding-bottom: 16px;
      }
      &:hover {
        background-color: ${COLORS.white};
        color: ${COLORS.vermilion};
        &:first-child {
          border-radius: 10px 10px 0 0;
        }
        &:last-child {
          border-radius: 0 0 10px 10px;
        }
      }
    }
  }
  &:hover,
  &:active {
    color: ${COLORS.darkGray};
    img {
      transform: rotate(0deg);
    }
    div {
      display: block;
      opacity: 1;
      transition: opacity 0.5s ease;
      color: ${COLORS.vermilion};
    }
  }

  @media (max-width: ${DESKTOP_1200}px) {
    margin: 0 15px;
    font: 400 14px/20px Roboto;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    margin: 0 12px;
    font: 400 14px/20px Roboto;
  }
`;

export const Arrow = styled.img`
  width: 10px;
  transition: 0.5s linear;
  margin-left: 10px;
  transform: ${(props) => (props.isOpen ? `rotate(0deg)` : `rotate(180deg)`)};
  @media (max-width: ${TABLET_992}px) {
    transform: ${(props) =>
      props.isServicesOpen ? `rotate(0deg)` : `rotate(90deg)`};
  }
`;

export const DropdownContent = styled.div`
  width: 160px;
  height: auto;
  border-radius: 11px;
  background-color: ${COLORS.vermilion};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 40px;
  right: -10px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ease;
  cursor: pointer;
  ${(props) =>
    props.isOpen
      ? `display: block;
        opacity: 1;
        transition: opacity 0.5s ease;`
      : `display: none;`};
  a,
  li {
    padding: 12px 16px;
    text-align: right;
    color: ${COLORS.white};
    display: block;
    text-transform: uppercase;
    font: 600 13px/18px Roboto;
    &:first-child {
      padding-top: 18px;
    }
    &:last-child {
      padding-bottom: 16px;
    }
    &:hover {
      background-color: ${COLORS.white};
      color: ${COLORS.vermilion};
      &:first-child {
        border-radius: 10px 10px 0 0;
      }
      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }

  @media (max-width: ${TABLET_992}px) {
    min-width: 160px;
    top: 60px;
    left: 0px;
    a,
    li {
      text-align: left;
    }
  }
`;

export const MenuMobile = styled.div`
  display: none;
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }
`;

export const MenuMobileButton = styled.div`
  position: absolute;
  left: 20px;
  top: 30px;
`;

export const HamburguerIcon = styled.img`
  width: 30px;
  padding: 0;
  cursor: pointer;
`;

export const MobileServices = styled.ul`
  ${(props) => (props.isServicesOpen ? `display: block;` : `display: none;`)};
`;

export const WhiteLine = styled.hr`
  width: 100%;
  background-color: ${COLORS.white};
  color: ${COLORS.white};
  margin: 0;
`;

export const PhoneLink = styled.a`
  display: ${(props) =>  props.mobile ? "none" : "flex"} !important;

  @media (max-width: ${MOBILE_460}px) {
    display: ${(props) =>  props.mobile ? "flex" : "none"} !important;
  }
`;
