import React from "react";
import { Helmet } from "react-helmet"
import Topbar from "../Topbar";
import Footer from "../Footer";

import { MainContainer } from "../../styles/globals/styles";

// Import SCSS file
import "./layout.scss";

const Layout = ({ children, className }) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en-US' }} />
      <div className={className ? "layout " + className : "layout "}>
        {/* <MainContainer> */}
          <Topbar />
          {children}
        {/* </MainContainer> */}
      </div>
    </>
  );
};

export default Layout;
